import { useEffect } from 'react';

import buildUrlUtm from '@/utils/buildUrlUtm';
import { isExternalUrl } from '@/utils/url';

export function useAttachUTMOnLinks() {
  useEffect(() => {
    function onClick(event: MouseEvent) {
      const target = event.target as HTMLElement;
      const origin = target.closest('a');
      if (origin) {
        if (isExternalUrl(origin.host)) {
          event.preventDefault();
          event.stopPropagation();
          if (origin.target) {
            return window.open(buildUrlUtm(origin.href), '_blank');
          }

          return (window.location = buildUrlUtm(origin.href) as (
            | string
            | Location
          ) &
            Location);
        }
      }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);
}
